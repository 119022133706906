<template>
  <div class="row">
    <div
      v-if="NotificationsEnabled"
      class="col-12 mt-5 d-flex flex-column text-start"
    >
      <div class="separator separator-content border-secondary my-8">
        <span class="w-250px">Notificaciones</span>
      </div>
      <span class="w-100">
        <font-awesome-icon
          v-if="checkSuscription"
          icon="circle-check"
          class="menu-icon text-success"
        />
        <font-awesome-icon
          v-if="!checkSuscription"
          icon="circle-xmark"
          class="menu-icon text-danger"
        />
        <i
          v-if="!checkSuscription"
          class="fa-solid fa-circle-xmark text-danger"
        ></i>
        Suscripción para recibir notificaciones.
      </span>
      <span class="w-100 mb-4">
        <!-- <font-awesome-icon
          v-if="checkNotification == 'granted'"
          icon="circle-check"
          class="menu-icon text-success"
        /> -->
        <!-- <font-awesome-icon
          v-if="checkNotification != 'granted'"
          icon="circle-xmark"
          class="menu-icon text-danger"
        /> -->
        Permiso del dispositivo para mostrar notificaciones.
      </span>
      <button @click="suscriptionTest" class="btn btn-primary mb-4 shadow">
        Recibir notificación de prueba
      </button>
      <button @click="resubscribe" class="btn btn-primary mb-4 shadow">
        Actualizar Suscripción
      </button>
      <button class="btn btn-danger mb-4 disabled">
        Eliminar Historial de Notificaciones
      </button>
    </div>
    <div class="col-12">
      <div class="separator separator-content border-secondary my-8">
        <span class="w-250px">Cuenta de Usuario</span>
      </div>
      <router-link
        :to="{ name: 'password-set' }"
        class="btn btn-primary w-100 shadow mb-4"
        >Actualizar Contraseña</router-link
      >
      <router-link
        :to="{ name: 'Logout' }"
        class="btn btn-danger w-100 shadow mb-4"
        >Cerrar Sesión</router-link
      >
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";

import API from "@/api";

let activeRegister = null;

const checkSuscription = localStorage.getItem("subscription");
const NotificationsEnabled = ref(false);
const checkNotification = ref("");
// const checkNotification = Notification.permission;

// const requestPermission = async () => {
//   const permission = await Notification.requestPermission();

//   if (permission !== "granted") {
//     return false;
//   }

//   return true;
// };

const subscription = async () => {
  try {
    // Service Worker
    activeRegister = await navigator.serviceWorker.register("/worker.js", {
      scope: "/",
    });

    // if (
    //   (await requestNotificationPermission()) == "granted" &&
    //   !localStorage.getItem("subscription")
    // ) {
    //   const subscription = await activeRegister.pushManager.subscribe({
    //     userVisibleOnly: true,
    //     applicationServerKey: urlBase64ToUint8Array(
    //       "BFnMvSL2RYP1xgj-Jod7H-QlGz9VUcxDjed5io-7evZsEQkDqozmZeB2ttd3WUCWhmwuYkTWa1Kt6d2dUIRoQd0"
    //     ),
    //   });

    //   await API.post("/v1/session/subscribe", subscription);

    //   localStorage.setItem("subscription", true);
    // }
  } catch (error) {
    console.log(error);
  }
};

const resubscribe = async () => {
  try {
    if ((await requestNotificationPermission()) != "granted") {
      throw new Error("Verifique los permisos en su dispositivo.");
    }

    // Obtener activeRegister utilizando navigator.serviceWorker.ready
    if (!activeRegister) {
      const registration = await navigator.serviceWorker.ready;
      activeRegister = registration;
    }

    // Usar la variable global "activeRegister"
    const subscription = await activeRegister.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        "BFnMvSL2RYP1xgj-Jod7H-QlGz9VUcxDjed5io-7evZsEQkDqozmZeB2ttd3WUCWhmwuYkTWa1Kt6d2dUIRoQd0"
      ),
    });

    await API.post("/v1/session/subscribe", subscription);

    localStorage.setItem("subscription", true);
  } catch (error) {
    console.log(error);
  }
};

const suscriptionTest = async () => {
  try {
    const response = await API.post("/v1/session/subscribe-test");
  } catch (error) {
    console.log(error);
  }
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const requestNotificationPermission = () => {
  if (NotificationsEnabled && Notification.permission === "granted") {
    console.log("Permiso de notificación previamente concedido.");
    return Promise.resolve("granted");
  }

  if (NotificationsEnabled && Notification.permission !== "denied") {
    return Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Permiso de notificación concedido.");
      } else {
        console.log("No se puede obtener permiso para notificar.");
      }
      return permission;
    });
  }

  return Promise.resolve("denied");
};

onMounted(async () => {
  if ("Notification" in window) {
    NotificationsEnabled.value = true;
    checkNotification.value = Notification.permission;
  }

  if ("serviceWorker" in navigator) {
    subscription().catch((err) => console.log(err));
  }
});
</script>
